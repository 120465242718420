import {
    Directive,
    HostListener,
    Input,
} from '@angular/core';

import {
    SelectComponent,
} from './select.component';

@Directive({
    selector: '[bkSelectItems]'
})
export class SelectItemsDirective {
    @Input() selectComponent: SelectComponent;

    constructor () {
    }

    @HostListener('blur', ['$event'])
    onBlurHandler (e: FocusEvent): void {
        const sc = this.selectComponent;
        if (sc.open && !sc.protected && !sc.protectedFromBlur) {
            sc.toggle(false);
        }
    }
}
