import {
    Component,
    Input,
    OnInit,
    Optional
} from '@angular/core';

import { FormDirective } from '../form.directive';

@Component({
    selector: 'bk-form-server-errors',
    templateUrl: './form-server-errors.component.html',
    styleUrls: ['./form-server-errors.component.css']
})
export class FormServerErrorsComponent implements OnInit {
    @Input() form: FormDirective;

    constructor (@Optional() public bkForm: FormDirective) {
    }

    ngOnInit (): void {
        if (this.form && !this.bkForm) {
            this.bkForm = this.form;
        }
    }
}
