import {
    Pipe,
    PipeTransform
} from '@angular/core';

@Pipe({
    name: 'styleBgImageUrl'
})
export class StyleBgImageUrlPipe implements PipeTransform {

    transform (url: string): string {
        return `url(${url})`;
    }
}
