<div>
    <input
        #fileInput
        hidden="true"
        type="file"
        onclick="this.value=null"
        (change)="fileChanged($event)"
        [accept]="accept"
        style="opacity: 0;"
    />
    <ng-content></ng-content>
</div>
