import {
    Component,
} from '@angular/core';

import {
    ValidationDisplayElementComponent
} from '../validation-display-element/validation-display-element.component';
import {
    ValidationDisplayHintsComponent
} from '../validation-display-hints/validation-display-hints.component';

@Component({
    selector: 'bk-validation-display-hints-element',
    templateUrl: './validation-display-hints-element.component.html',
    styleUrls: ['./validation-display-hints-element.component.scss']
})
export class ValidationDisplayHintsElementComponent extends ValidationDisplayElementComponent {
    constructor (
        public validationDisplay: ValidationDisplayHintsComponent
    ) {
        super(validationDisplay);
    }
}
