<div class="bk-select" [ngClass]="{open: open, closed: !open, focused: hasFocus}">
    <input class="bk-select-display" bkSelectDisplay #selectDisplay="SelectDisplayDirective" [selectComponent]="this" tabindex="-1" type="text" [(ngModel)]="toDisplay" />
    <div bkSelectListHolder #selectListHolder="SelectListHolderDirective" class="bk-select-list-holder" [ngClass]="{open: open, closed: !open}">
        <input class="bk-select-behind-auto-indicator" type="text" tabindex="-1" [(ngModel)]="suggestedResultText" />
        <input
            bkSelectInput
            #selectInput="SelectInputDirective"
            [selectComponent]="this"
            class="bk-select-search-text"
            type="text"
            [(ngModel)]="searchText"
        />
        <div bkSelectItems class="items" tabindex="-1" [selectComponent]="this">
            <div bkSelectItem class="item" *ngFor="let item of list" [selectItem]="item" [selectComponent]="this" tabindex="-1" [ngClass]="{listed: item.showing}">
                <span *ngIf="!item.html">{{display(item)}}</span>
                <div *ngIf="item.html">HTML ?</div> <!-- ng-bind-html="item.html" -->
            </div>
        </div>
    </div>
</div>
