<div class="bk-select" [ngClass]="{open: open, closed: !open, focused: hasFocus}">
    <div
        class="input bk-select-display"
        bkSelectDisplay
        #selectDisplay="SelectDisplayDirective"
        [selectComponent]="this"
        tabindex="-1"
        type="text"
        [innerHtml]="toDisplay"
        [ngClass]="{'disabled': disabled, 'error': this.control?.errors}"
    ></div>
    <div bkSelectListHolder #selectListHolder="SelectListHolderDirective" class="bk-select-list-holder" [ngClass]="{open: open, closed: !open}">
        <input
            type="text"
            autocomplete="country"
            (input)="onCountryAutofill()"
            style="opacity: 0; height: 0px; overflow: hidden; position: absolute;"
            tabindex="-1"
        />
        <input
            class="bk-select-behind-auto-indicator"
            type="text"
            tabindex="-1"
            [(ngModel)]="suggestedResultText"
        />
        <input
            bkSelectInput
            #selectInput="SelectInputDirective"
            [selectComponent]="this"
            class="bk-select-search-text"
            type="text"
            [(ngModel)]="searchText"
            autocomplete="new-password"
        />
        <div class="items">
            <div class="item" tabindex="-1" [ngClass]="{listed: !listShowingKeys?.length}">
                <div>No results</div>
            </div>
            <div bkSelectItem class="item" *ngFor="let item of list" [selectItem]="item" [selectComponent]="this" tabindex="-1" [ngClass]="{listed: item.showing}">
                <div>{{item?.data?.name}}</div>
            </div>
        </div>
    </div>
</div>
