import {
    Directive,
    ElementRef,
} from '@angular/core';

@Directive({
    selector: '[bkSelectListHolder]',
    exportAs: 'SelectListHolderDirective'
})
export class SelectListHolderDirective {
    constructor (public el: ElementRef) {
        // This exists to make itself available to select component.
    }
}
